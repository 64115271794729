import { gql } from '@apollo/client';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { ROLES } from 'lib/utils/constants';

const IS_TRAINER = gql`
  query isTrainer($email: String!) {
    isTrainer(email: $email)
  }
`;

const IS_ORG_REP = gql`
  query isOrgRep($email: String!) {
    isOrgRep(email: $email)
  }
`;

const hasRequiredRoleForRoute = (pathName, role) => {
  if (pathName.includes('/admin')) {
    const adminRoles = [ROLES.ADMIN, ROLES.ORG_REP];

    return adminRoles.includes(role);
  }

  if (pathName.includes('/trainer')) {
    const trainerRoles = [ROLES.ADMIN, ROLES.ORG_REP, ROLES.TRAINER];

    return trainerRoles.includes(role);
  }

  return true;
};

const calculateUserRole = async ({ client, user }) => {
  try {
    const auth0Roles = user['https://leadernet.work/roles'];
    const isAdmin = auth0Roles.some((role) => role === ROLES.ADMIN);

    if (isAdmin) return ROLES.ADMIN;

    const {
      data: { isOrgRep },
    } = await client.query({
      query: IS_ORG_REP,
      variables: { email: user.email },
    });

    if (isOrgRep) return ROLES.ORG_REP;

    const {
      data: { isTrainer },
    } = await client.query({
      query: IS_TRAINER,
      variables: { email: user.email },
    });

    if (isTrainer) return ROLES.TRAINER;
  } catch (e) {
    Sentry.captureException(e);
  }

  return '';
};

export const useIsTrainer = ({ email }) => {
  const doc = useQuery(IS_TRAINER, {
    variables: {
      email,
    },
    skip: !email,
  });

  return doc.data?.isTrainer;
};

export default function useRoleAuthorization() {
  const [returnRole, setReturnRole] = useState('');
  const client = useApolloClient();
  const router = useRouter();
  const { user } = useUser();
  const path = router.pathname;
  const email = user ? user.email : '';
  let userRole;
  let authorizedForRoute;

  useEffect(() => {
    if (!userRole) return;

    const throwErrorToSentry = async () => {
      Sentry.captureException(
        new Error('User is unauthorized to access the admin page. Redirecting.')
      );
      await Sentry.flush(2000);
    };

    authorizedForRoute = hasRequiredRoleForRoute(path, userRole);
    if (!authorizedForRoute) {
      throwErrorToSentry();
      router.replace('/');
    }
  }, []);

  useEffect(() => {
    const getUserRole = async () => {
      if (!user || userRole) return;

      userRole = await calculateUserRole({ client, user });

      setReturnRole(userRole);
      authorizedForRoute = hasRequiredRoleForRoute(path, userRole);

      if (!authorizedForRoute) {
        router.replace('/');
      }
    };

    getUserRole();
  }, [returnRole]);

  return { role: userRole || returnRole, email };
}
